import Request from "./request";

class CollectRequest extends Request {
    /**
     * 학생 스케쥴 등록 API
     * 
     * params
     *      data
                {
                    "inbound": {
                        "classId": "학원 강의 아이디",
                        "stationId": "정류장 아이디"
                    },
                    "outbound": {
                        "classId": "학원 강의 아이디",
                        "stationId": "정류장 아이디"
                    },
                    "weekday": 0
                }
     * 
     * callback 
     *      콜백함수
     */
    createSchedules = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post(`/collect/schedules`, request);
    };
    /**
     * 학생 스케쥴 보기 API
     * 
     * params
     *      x
     * 
     * callback 
     *      콜백함수
     * 
     * return
     *      [
                {
                    "inbound": {
                    "lat": 0,
                    "lng": 0,
                    "stationName": "정류장이름",
                    "time": "12:00"
                    },
                    "outbound": {
                    "lat": 0,
                    "lng": 0,
                    "stationName": "정류장이름",
                    "time": "12:00"
                    },
                    "scheduleId": "스케쥴 아이디",
                    "weekday": 2
                }
            ]
     */
    getSchedules = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/collect/schedules`, request);
    };
    /**
     * 학생 스케쥴 학생 스케쥴 상세보기 API
     * 
     * params
     *      schedule_id 스케줄 PK
     * 
     * callback 
     *      콜백함수
     * 
     * return
     *      {
                "inbound": {
                    "lat": 0,
                    "lng": 0,
                    "stationName": "정류장이름",
                    "time": "12:00"
                },
                "outbound": {
                    "lat": 0,
                    "lng": 0,
                    "stationName": "정류장이름",
                    "time": "12:00"
                },
                "scheduleId": "스케쥴 아이디",
                "weekday": 2
            }
     */
    getSchedulesById = ({ schedule_id }, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/collect/schedules/${schedule_id}`, request);
    };
    /**
     * 학생 스케쥴 삭제 API
     * 
     * params
     *      id 삭제할 스케쥴의 id array
     * 
     * callback 
     *      콜백함수
     */
    deleteSchedules = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.del(`/collect/schedules`, request);

    };

    /**
     * 학원 - 모든 강의 시간에 대한 요일들 가져오기
     **/
    getAllAcademyClass = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/collect/classes/all`, request);
    };
    /**
     * 학원 - 강의 시간표 조회 API
     * 
     * params
     *      weekday         2(월), 3(화), 4(수), 5(목), 6(금) ,7(토), 1(일) (int)
     * 
     * callback 
     *      콜백함수
     * 
     * return
     *      [
                {
                    "classId": 43,
                    "startTime": "13:23",
                    "endTime": "15:34"
                },
                ...
            ]
     */
    getClassesByWeekday = ({ weekday }, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/collect/classes?weekday=${weekday}`, request);
    };
    /**
     * 학원 - 강의 - 노선 정보 조회 API
     * 
     * params
     *      class_id        학원 강의 아이디 (int)
     *      weekday         2(월), 3(화), 4(수), 5(목), 6(금) ,7(토), 1(일) (int)
     *      schedule_type   0(등원), 1(하원) (int)
     * 
     * callback 
     *      콜백함수
     * 
     * retrun
            {
                "status": 1,
                "code": "OK",
                    "data": {
                        "buses": [
                            {
                                "busId": 1,
                                "name": "테스트버스1호차",
                                "stations": [
                                    {
                                        "stationId": 470,
                                        "name": "성남시청",
                                        "lng": 127.1256987695506,
                                        "lat": 37.418777882652535
                                    },
                                    {
                                        "stationId": 471,
                                        "name": "야탑초등학교",
                                        "lng": 127.12640713820272,
                                        "lat": 37.41679939260422
                                    },
                                    {
                                        "stationId": 474,
                                        "name": "야탑 119 안전센터",
                                        "lng": 127.135703303104,
                                        "lat": 37.416356598755904
                                    }
                                ]
                            },
                            {
                                "busId": 2,
                                "name": "테스트버스2호차",
                                "stations": [
                                    {
                                        "stationId": 470,
                                        "name": "여수햇살유치원",
                                        "lng": 127.13466836324538,
                                        "lat": 37.418772510598785
                                    },
                                    {
                                        "stationId": 471,
                                        "name": "여수초등학교",
                                        "lng": 127.1323877133677,
                                        "lat": 37.41950491098405
                                    },
                                    {
                                        "stationId": 474,
                                        "name": "한솔마을 2단지 정류장 (LG)",
                                        "lng": 127.11920880900541,
                                        "lat": 37.365299932612146
                                    }
                                ]
                            }
                        ],
                        "academyStation": {
                            "stationId": 469,
                            "name": "리버스랩",
                            "lng": 127.13062066137597,
                            "lat": 37.42002496179864
                        }
                    }
                }
     */
    getStation = ({ class_id, weekday, course_type, start_time }, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/collect/classes/${class_id}/stations?weekday=${weekday}&course_type=${course_type}&start_time=${start_time}`, request);
    };
};
export default CollectRequest;
